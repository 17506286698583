<template>
    <div class="not-found">
        <div class="row">
        <img class="not-found-img" src="../assets/images/404.png" alt="">
        <h3>{{ $t('pageNotFound') }}</h3>
        <div class="btn-wrapper">
            <button @click="goBack" class="btn btn-back privacy-policy" :to="`/${$i18n.locale}/home`">{{ $t('back')}}</button>
        </div>
    </div>
    </div>
</template>

<script>
    export default {
        methods:{
          goBack(){
            this.$router.go(-1);
          }
        }
    }
</script>

<style scoped>
    .not-found{
        padding-top: 15vh;
    }
    .not-found h3{
        text-align: center;
    }
    .not-found-img{
        height: 500px;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    
    }
    .btn-wrapper{
        text-align: center;
    }
    .btn-back{
        margin-top: 15px;
        background-color: #606060;
        color: #fff;
        font-weight: 600;
        width: 100px;
    }
</style>